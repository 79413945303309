import Head from 'next/head'

export default function GlobalHead() {
  return (
    <Head>
      <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
      <meta httpEquiv='Pragma' content='no-cache' />
      <meta httpEquiv='Cache-Control' content='max-age=0' />
      <meta httpEquiv='Cache-Control' content='no-cache' />
      <meta
        httpEquiv='Cache-Control'
        content='no-cache, no-store, must-revalidate'
      />
      <meta httpEquiv='Expires' content='Tue, 23 Feb 1993 01:00:00 GMT' />
      <meta httpEquiv='Expires' content='-1' />
      <meta name='viewport' content='width=device-width,viewport-fit=cover' />
      <meta property='og:locale' content='ko_KR' />
      <meta property='og:type' content='website' />
      <meta property='og:site_name' content={process.env.META_OG_SITE_NAME} />
      <meta
        name='description'
        key='description'
        content='카카오모빌리티 기업 소개, 제공 서비스, 인재 채용 안내'
      />
      <meta
        property='og:title'
        key='og:title'
        content={process.env.META_OG_TITLE}
      />
      <meta
        property='og:description'
        key='og:description'
        content={process.env.META_OG_DESCRIPTION}
      />
      <meta
        property='og:image'
        key='og:image'
        content={`${process.env.PUBLIC_URL}opengraph.jpg`}
      />

      <meta
        property='twitter:title'
        key='twitter:title'
        content={process.env.META_OG_TITLE}
      />
      <meta
        property='twitter:description'
        key='twitter:description'
        content={process.env.META_OG_DESCRIPTION}
      />
      <meta
        property='twitter:image'
        key='twitter:image'
        content={`${process.env.PUBLIC_URL}opengraph.jpg`}
      />
      <meta
        name='twitter:card'
        key='twitter:card'
        content='summary_large_image'
      />
    </Head>
  )
}
