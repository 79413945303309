import { useEffect } from 'react'

export default function useGuideDog() {
  useEffect(() => {
    if (process.env.BUILD_ENV === 'production') {
      return
    }
    const script = document.createElement('script')
    script.src = 'https://t1-beta.kakaocdn.net/kakaomobility/guide-dog/index.js'
    script.onload = () => {
      ;(window as any).guideDog.init()
    }
    document.body.appendChild(script)
  }, [])
}
